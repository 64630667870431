<template>
  <v-expansion-panels
    v-model="panelsModel"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <section class="d-flex align-center">
          <v-icon>mdi-tag</v-icon>
          <p class="text-h6 font-weight-bold ml-3 mb-0">
            Listings
          </p>
        </section>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="listingsPanelHeaders"
          :items="listingsPanelItems"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item.price="{ item }">
            <span>
              <v-icon small>$ethereumIcon</v-icon>
              <span class="font-weight-bold mx-1">{{ item.price | formatEthereumPrice }}</span>
              <span>ETH</span>
            </span>
          </template>
          <template v-slot:item.usdprice="{ item }">
            <span>{{ item.usdPrice | toCurrency }}</span>
          </template>
          <template v-slot:item.actions>
            <v-btn
              class="text-capitalize"
              color="primary"
              outlined
            >
              Buy
            </v-btn>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Listing',
  data: () => ({
    panelsModel: [0, 1],
    listingsPanelHeaders: [
      {
        text: 'Price',
        align: 'left',
        value: 'price',
      },
      {
        text: 'USD Price',
        align: 'left',
        value: 'usdprice',
      },
      {
        text: 'Expiration',
        align: 'left',
        value: 'expiration',
      },
      {
        text: 'From',
        align: 'left',
        value: 'from',
      },
      {
        text: '',
        align: 'right',
        value: 'actions',
      },
    ],
    listingsPanelItems: [
      {
        price: 0.1,
        usdPrice: 403.45,
        expiration: 'in 6 months',
        from: 'serebass',
      },
      {
        price: 0.18,
        usdPrice: 700.45,
        expiration: 'in 2 years',
        from: 'Chollathan',
      },
      {
        price: 1.45,
        usdPrice: 320.45,
        expiration: 'in 1 years',
        from: 'Aggregus',
      },
    ],
  }),
};
</script>
